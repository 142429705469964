
import { computed } from 'vue';
import { useDonationData } from './composables/donation-data';
import { useCurrency } from '../../../common/js/composables/currency';
import moment from 'moment';
import LoadingDots from './components/LoadingDots.vue';
export default {
	components: {
		LoadingDots
	},
	props: {
		donationId: {
			required: true
		},
		donationType: {
			required: true
		}
	},
	setup(props) {
		const { formatAmount } = useCurrency();
		const { summaryExists, getDonationAmount, getDonationDate } = useDonationData();
		const hasDonationId = computed(() => summaryExists(props.donationId));
		const formattedAmount = computed(() => formatAmount(getDonationAmount(props.donationId)));
		const formattedDate = computed(() => moment(getDonationDate(props.donationId)).format('YYYY.MM.DD'));
		return {
			hasDonationId,
			formattedAmount,
			formattedDate
		};
	}
};
