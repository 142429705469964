
export default {
	props: {
		dots: {
			type: Number,
			default: 3
		}
	},
	data() {
		return {
			activeDots: '',
			timeoutHandle: null
		};
	},
	mounted() {
		this.startAnimation();
	},
	beforeDestroy() {
		this.clearAnimation();
	},
	methods: {
		startAnimation() {
			this.timeoutHandle = setInterval(() => {
				if (this.activeDots.length < this.dots) {
					this.activeDots += '.';
				} else {
					this.activeDots = '';
				}
			}, 500);
		},
		clearAnimation() {
			clearInterval(this.timeoutHandle);
		}
	}
};
